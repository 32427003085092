import Lottie from "lottie-react";

import Button from "@library/frontend/design-system/Button/Button";
import Group from "@library/frontend/design-system/Group/Group";

import animation from "./anim-btn.json";

import { BaseProps } from "@library/frontend/interfaces/BaseProps";
import { classNames } from "@library/tools/utils";
import "./ForInvestorsBtn.scss";
import { paths } from "@app/frontend/routing/paths";

export const ForInvestorsBtn = ({ className }: BaseProps) => {
  return (
    <Button as="a" href={paths.investors} className={classNames("for-investors", className)}>
      <Lottie animationData={animation} loop={true} className="lottie" />
      <Group colGap={8} className="content">
        <p className="label white-0">For investors</p>
        <img src="/images/logos/uniswap-white.svg" />
      </Group>
    </Button>
  );
};
