import { Outlet } from "react-router-dom";
import { MobileMenuProvider } from "../components/Navbar/components/mobile/MenuMobile.ctx";
import { paths } from "@app/frontend/routing/paths";
import Home from "./Home/Home";
import Investors from "./Investors/Investors";

const App = () => {
  return (
    <MobileMenuProvider>
      <Outlet />
    </MobileMenuProvider>
  );
};

export default {
  path: paths.app,
  element: <App />,
  children: [Home, Investors]
};
