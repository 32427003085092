import { ImgSrc } from "@library/frontend/interfaces/ImgSrc";
import Button from "@library/frontend/design-system/Button/Button";

import { TELEGRAM_URL, X_URL } from "@app/project/socials";
import Group from "@library/frontend/design-system/Group/Group";

import "./Socials.scss";

interface Social {
  url: string;
  img: ImgSrc;
}

const socials: Social[] = [
  {
    img: "/images/socials/x.svg",
    url: X_URL
  },
  {
    img: "/images/socials/telegram.svg",
    url: TELEGRAM_URL
  }
];

export const Socials = () => {
  return (
    <Group className="socials" colGap={8}>
      {socials.map((s, id) => (
        <Social key={id} {...s} />
      ))}
    </Group>
  );
};

const Social = (social: Social) => {
  return (
    <Button as="a" href={social.url}>
      <Group className="social" justifyContent="center">
        <img src={social.img} />
      </Group>
    </Button>
  );
};
