import Lottie from "lottie-react";

import Group from "@library/frontend/design-system/Group/Group";
import animation from "../anim-btn.json";
import Button from "@library/frontend/design-system/Button/Button";
import { paths } from "@app/frontend/routing/paths";

export const ForInvestorsSmall = () => {
  return (
    <Button as="a" href={paths.investors}>
      <div
        style={{ width: "80px", height: "40px", position: "relative", overflow: "hidden", borderRadius: "8px" }}
        className="color-btn"
      >
        <Lottie
          animationData={animation}
          loop={true}
          style={{ position: "absolute", left: "0px", top: "50%", transform: "translateY(-50%)" }}
        />
        <Group colGap={8} className="absolute-center" fullSize justifyContent="center">
          <img src="/images/logos/uniswap-white.svg" />
        </Group>
      </div>
    </Button>
  );
};
