import { IChildren } from "@library/frontend/interfaces/IChildren";
import { useMobileMenuCtx } from "./MenuMobile.ctx";
import { BaseProps } from "@library/frontend/interfaces/BaseProps";

export const CloseMenu = ({ children, className }: IChildren & BaseProps) => {
  const mobileMenu = useMobileMenuCtx();
  return (
    <button onClick={mobileMenu.close} className={className}>
      {children}
    </button>
  );
};
