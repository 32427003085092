import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

import Router from "./app/frontend/routing/router";

import "@scss/grid.scss";
import "@scss/utils.scss";
import "@scss/responsive.scss";
import "@scss/design-system.scss";

import "@app-scss/typography.scss";
import "@app-scss/root.scss";
import "@app-scss/utils.scss";
import "@app-scss/reset.scss";
import "@app-scss/layers.scss";

const rootElement = document.getElementById("root");
const ReactRoot = ReactDOM.createRoot(rootElement!);

ReactRoot.render(
  <HelmetProvider>
    <Router />
  </HelmetProvider>
);
