import { IChildren } from "@library/frontend/interfaces/IChildren";

import { BaseProps } from "@library/frontend/interfaces/BaseProps";
import { classNames } from "@library/tools/utils";

import "./PaddingSectionTemplate.scss";

const PaddingSectionTemplate = ({ children, className }: IChildren & BaseProps) => {
  return <div className={classNames("pl-md-5 pr-md-5 padding-template h-xs-i", className)}>{children}</div>;
};

export default PaddingSectionTemplate;
