import Group from "@library/frontend/design-system/Group/Group";

import { LoginPlatformBtn } from "./LoginPlatformBtn";
import { ForInvestorsBtn } from "./ForInvestorsBtn";

import "./Navigation.scss";

const Navigation = () => {
  return (
    <Group colGap={8} className="navigation">
      <LoginPlatformBtn />
      <ForInvestorsBtn />
    </Group>
  );
};

export default Navigation;
