import PaddingSectionTemplate from "@app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Group from "@library/frontend/design-system/Group/Group";
import Stack from "@library/frontend/design-system/Stack/Stack";

import "./Panel.scss";
import useIsMobile from "@library/frontend/hooks/useIsMobile";

export const Panel = () => {
  const isMobile = useIsMobile("md");
  return (
    <section className="panel">
      <div className="bg">
        <PaddingSectionTemplate>
          <Group fullSize justifyContent="center" alignItems={isMobile ? "flex-start" : "center"}>
            <Stack className="background mb-xs-0 mb-md-5" rowGap={8} alignItems="center" fullWidth>
              <h4 className="title t-xs-c">AI Agents for Career Management</h4>
              <p className="description t-xs-c">
                AI agents in our platform redefine career management, creating limitless career potential and setting
                the standard for a new era of Recruitment 5.0.
              </p>
            </Stack>
          </Group>
        </PaddingSectionTemplate>
      </div>
    </section>
  );
};
