import useString from "./useString";

interface StateMachine<StepType> {
  step: StepType;
  isLastStep: boolean;
  isFirstStep: boolean;
  next(): void;
  reset(): void;
}

export const useStraightStateMachine = <T extends string>(initialStates: T[]): StateMachine<T> => {
  const currentState = useString<T>(initialStates[0]);

  const next = () => {
    const currentStateIndex = initialStates.findIndex((c) => c === currentState.value);
    const nextIndex = currentStateIndex + 1;
    const nextValue = initialStates[nextIndex];

    if (!!nextValue) {
      currentState.update(nextValue);
    }
  };

  const reset = currentState.reset;

  const step = currentState.value as T;
  const isLastStep = currentState.value === initialStates[initialStates.length - 1];
  const isFirstStep = currentState.value === initialStates[0];

  return { step, next, isLastStep, isFirstStep, reset };
};
