import { IChildren } from "@library/frontend/interfaces/IChildren";
import React, { useEffect } from "react";
import useCompleteCode, { CompleteCodeReturn } from "./useCompleteCode";
import useCompleteFile, { CompleteFileReturn } from "./useCompleteFile";
import useVerifyFile from "./useVerifyFile";
import { VerifyComponents } from "./VerifyComponents";
import useVerifyCode from "./useVerifyCode";
import useBoolean from "@library/frontend/hooks/useBoolean";
import useString from "@library/frontend/hooks/useString";

interface IVerifyCtx {
  isBeenVerifying: boolean;
  verifyByCode(): void;
  verifyByFile(): void;
  verifyingStep: StepsKeys;
  resetVerifying(): void;
  completeFile: CompleteFileReturn;
  completeCode: CompleteCodeReturn;
}

const VerifyCtx = React.createContext(null as any);

const stepsKeys = ["none", "uploading-cv", "sending-code", "checking-on-blockchain", "result"] as const;
export type StepsKeys = (typeof stepsKeys)[number];

type VerifyMethod = "by-code" | "by-file";

export const VerifyProvider = ({ children }: IChildren) => {
  const verifyMethod = useString<VerifyMethod>("by-file");

  const completeFile = useCompleteFile();
  const completeCode = useCompleteCode();

  const verifyFile = useVerifyFile();
  const verifyCode = useVerifyCode();

  const verifyByFile = () => {
    if (!completeFile.isCorrect) return;

    verifyMethod.update("by-file");
    verifyFile.start();
  };

  useEffect(() => {
    if (!completeFile.isCorrect) return;

    verifyByFile();
  }, [completeFile.isCorrect, completeFile.fileInput.value]);

  const verifyByCode = () => {
    if (!completeCode.isCorrect) return;

    verifyMethod.update("by-code");
    verifyCode.start();
  };

  const resetVerifying = () => {
    verifyFile.reset();
    verifyCode.reset();
  };

  const isBeenVerifying = verifyFile.isVerifying || verifyCode.isVerifying;

  const methods = {
    "by-file": verifyFile.verifyingStep,
    "by-code": verifyCode.verifyingStep
  };

  const verifyingStep = methods[verifyMethod.value];

  return (
    <VerifyCtx.Provider
      value={{ isBeenVerifying, verifyByCode, verifyByFile, verifyingStep, resetVerifying, completeFile, completeCode }}
    >
      {children}
      <VerifyComponents />
    </VerifyCtx.Provider>
  );
};

export const useVerifyCtx = (): IVerifyCtx => React.useContext(VerifyCtx);
