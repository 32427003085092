import { ReactNode } from "react";

import Group from "@library/frontend/design-system/Group/Group";
import PaddingSectionTemplate from "src/app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Stack from "@library/frontend/design-system/Stack/Stack";
import Button from "@library/frontend/design-system/Button/Button";

import { CHATGPT_TOOL_URL } from "@integrations/external-links";
import { ImgSrc } from "@library/frontend/interfaces/ImgSrc";
import { HelpingCard } from "@app/frontend/view/components/Card/HelpingCard";
import { classNames } from "@library/tools/utils";
import useIsMobile from "@library/frontend/hooks/useIsMobile";
import { X_URL } from "@app/project/socials";

import "./Explainers.scss";
import { iconsSet } from "@app/frontend/resources/iconsSet";
import { ExplainersKeys, ExplainersProvider, useExplainersCtx } from "./Explainers.ctx";
import Portal from "@library/frontend/design-system/Portal/Portal";
import useClickOutside from "@library/frontend/hooks/useClickOutside";

type ExplainerObj = {
  id: ExplainersKeys;
  img: ImgSrc;
  title: string;
  description: ReactNode;
  className: string;
};

const explainer1: ExplainerObj = {
  id: "1",
  img: "/images/pages/Home/explainers/1.jpg",
  title: "New Level of Trust",
  description: (
    <p className="description">"Building trust and transparency with blockchain for a seamless hiring experience."</p>
  ),
  className: "xs-12 md-6 xl-7"
};

const explainer2: ExplainerObj = {
  id: "2",
  img: "/images/pages/Home/explainers/2.jpg",
  title: "Smart CV",
  description: (
    <p className="description">
      "Revolutionizing hiring with verified and dynamic career profiles enabled by advanced AI."
    </p>
  ),
  className: "xs-12 md-6 xl-5"
};

const explainer3: ExplainerObj = {
  id: "3",
  img: "/images/pages/Home/explainers/3.jpg",
  title: "Career Management",
  description: (
    <p className="description">
      "Empowering modern recruitment with AI-driven career solutions, including leveraging{" "}
      <a href={CHATGPT_TOOL_URL} className="underline" target="_blank">
        ChatGPT’s AI tools
      </a>{" "}
      to create personalized CVs."
    </p>
  ),
  className: "xs-12 off-xs-0 md-6 off-md-3"
};

const Explainer = (explainer: ExplainerObj) => {
  const { selectVideo } = useExplainersCtx();
  const isMobile = useIsMobile("lg");

  return (
    <div className={explainer.className} style={{ paddingLeft: "4px", paddingRight: "4px" }}>
      <HelpingCard className={classNames("p-xs-3 pl-xs-3 pl-lg-5 card")}>
        <Group fullWidth colGap={16} rowGap={16} className={"wrap-reverse"}>
          <Stack fullHeight justifyContent="center" rowGap={8} style={{ width: isMobile ? "100%" : "48%" }}>
            <h5 className="title">{explainer.title}</h5>
            {explainer.description}
          </Stack>
          <Button
            as="button"
            onClick={() => selectVideo(explainer.id)}
            className="video image relative"
            style={{
              width: isMobile ? "100%" : "48%",
              backgroundImage: `url(${explainer.img})`
            }}
          >
            <div className="apla"></div>
            <iconsSet.play className="absolute-center play-icon" />
          </Button>
        </Group>
      </HelpingCard>
    </div>
  );
};

export const Explainers = () => {
  return (
    <section className="explainers">
      <PaddingSectionTemplate>
        <Group rowGap={8} fullWidth className="wrap">
          <ExplainersProvider>
            <Explainer {...explainer1} />
            <Explainer {...explainer2} />
            <Explainer {...explainer3} />
            <ModalWithBg />
          </ExplainersProvider>
        </Group>
        <Button as="a" href={X_URL} className="w-xs-100 mt-xs-1 d-b">
          <Group colGap={16} className="p-xs-3 m-xs-a">
            <p>{"Check our profile >"}</p>
            <img src="/images/socials/x-dark.svg" />
          </Group>
        </Button>
      </PaddingSectionTemplate>
    </section>
  );
};

const ModalWithBg = () => {
  const explainers = useExplainersCtx();
  const ref = useClickOutside(explainers.resetVideo);

  if (!explainers.vidId || !explainers.currentVideo) return <></>;

  return (
    <Portal id={"overlay"}>
      <div className="background">
        <div className="modal">
          <div className="video-wrapper">
            <iframe
              ref={ref as any}
              id="twitter-widget-3"
              allowFullScreen={true}
              title="X Post"
              src={explainers.currentVideo}
              data-tweet-id="1881603041624817756"
            ></iframe>
          </div>
        </div>
      </div>
    </Portal>
  );
};
