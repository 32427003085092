import { useState } from "react";

const useInput = (initial?: string) => {
  const [value, setValue] = useState(initial || "");
  const update = (newAdd: string) => setValue(newAdd);
  const reset = () => setValue("");

  const isEmpty = value === "";

  return { value, update, reset, isEmpty };
};

export default useInput;
