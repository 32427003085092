import PaddingSectionTemplate from "@app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Group from "@library/frontend/design-system/Group/Group";
import Stack from "@library/frontend/design-system/Stack/Stack";

import { copyString } from "@library/tools/utils";

import Button from "@library/frontend/design-system/Button/Button";
import {
  TOKEN_ADDRESS,
  TOKEN_TICKER_REAL,
  TOKEN_TICKER_TOKEN,
  TOKEN_TOTAL_SUPPLY_FORMATTED
} from "@integrations/qcv-token";

import { HelpingCard } from "@app/frontend/view/components/Card/HelpingCard";
import { COIN_GECKO_URL, COIN_MARKET_CAP_URL, UNISWAP_URL } from "@app/project/exchanges";
import { useAddTokenSaga } from "@app/domain/Metamask/addTokenSaga";

import "./Info.scss";

const BOXES_GAP = 8;

export const Info = () => {
  return (
    <section className="info">
      <PaddingSectionTemplate>
        <Stack fullWidth rowGap={BOXES_GAP}>
          <Group
            fullWidth
            colGap={BOXES_GAP}
            rowGap={BOXES_GAP}
            alignItems="stretch"
            justifyContent="center"
            className="top"
          >
            <Stack fullWidth rowGap={BOXES_GAP} justifyContent="stretch" alignItems="stretch" className="h-xs-u">
              <TokenInfo />
              <Group colGap={BOXES_GAP} rowGap={BOXES_GAP} fullSize className="mid">
                <Stack rowGap={BOXES_GAP} style={{ width: "100%" }} className="h-xs-mc h-md-100">
                  <Group fullWidth colGap={BOXES_GAP}>
                    <CoinMarketCap />
                    <CoinGecko />
                  </Group>
                  <AddToken />
                </Stack>
                <Trade />
              </Group>
            </Stack>
            <Tokenomics />
          </Group>
          <Utilities />
        </Stack>
      </PaddingSectionTemplate>
    </section>
  );
};

const TokenInfo = () => {
  return (
    <HelpingCard className="d-f token-info">
      <Stack rowGap={24} fullWidth>
        <Group colGap={8} fullWidth justifyContent="flex-start">
          <h5>Token Info</h5>
          <img src="/images/logos/token-image.svg" width={40} height={40} />
        </Group>
        <Group colGap={16} rowGap={16} alignItems="flex-start" className="wrap" fullWidth>
          <Stack rowGap={8}>
            <p className="label">Token Chain</p>
            <img src="/images/logos/base.svg" width={75} />
          </Stack>
          <Stack rowGap={8}>
            <p className="label">Token Ticker</p>
            <p className="d1">{TOKEN_TICKER_REAL}</p>
          </Stack>
          <Stack rowGap={8}>
            <p className="label">Category</p>
            <p className="d1">AI</p>
          </Stack>
          <Stack>
            <p className="label">Token Address</p>
            <Group colGap={8} fullWidth>
              <p className="d2">{TOKEN_ADDRESS}</p>
              <Button as="button" onClick={() => copyString(TOKEN_ADDRESS)} className="copy-btn disabled">
                <img src="/images/icons/copy-24.svg" />
              </Button>
            </Group>
          </Stack>
        </Group>
      </Stack>
    </HelpingCard>
  );
};

const Tokenomics = () => {
  return (
    <HelpingCard style={{ maxWidth: "323px" }} className="tokenomics default">
      <Stack fullSize rowGap={16}>
        <h5>Tokenomics</h5>
        <img
          src="/images/pages/Investors/info/tokenomics.svg"
          className="w-xs-100"
          style={{ maxWidth: "min-content" }}
        />
        <Stack fullWidth rowGap={12}>
          <Stack fullWidth rowGap={2}>
            <p className="label">Total Supply (Deflationary)</p>
            <h6>{TOKEN_TOTAL_SUPPLY_FORMATTED}</h6>
          </Stack>
          <Stack fullWidth rowGap={2}>
            <p className="label">Fees</p>
            <p className="description" style={{ whiteSpace: "pre-wrap" }}>
              {"Buy 1% | Sell 1%"}
            </p>
          </Stack>
          <p className="description">Supporting marketing, platform growth, and global expansion.</p>
        </Stack>
      </Stack>
    </HelpingCard>
  );
};

const CoinMarketCap = () => {
  return (
    <HelpingCard className="default disabled">
      <Button as="a" href={COIN_MARKET_CAP_URL}>
        <Stack fullWidth rowGap={16} alignItems="center">
          <img src="/images/logos/coinmarketcap.svg" />
          <Group colGap={2} alignItems="center">
            <p className="p2 semi-bold coinmarket-color">CoinMarketCap</p>
            <img src="/images/icons/arrow-top-right.svg" />
          </Group>
        </Stack>
      </Button>
    </HelpingCard>
  );
};

const CoinGecko = () => {
  return (
    <HelpingCard className="default disabled">
      <Button as="a" href={COIN_GECKO_URL}>
        <Stack fullWidth rowGap={16} alignItems="center">
          <img src="/images/logos/coingecko.svg" />
          <Group colGap={2} alignItems="center">
            <p className="p2 semi-bold coingecko-color">CoinGecko</p>
            <img src="/images/icons/arrow-top-right-green.svg" />
          </Group>
        </Stack>
      </Button>
    </HelpingCard>
  );
};

const AddToken = () => {
  const addToken = useAddTokenSaga();
  return (
    <HelpingCard className="h-xs-100 add-token disabled">
      <Stack fullSize justifyContent="space-between" alignItems="center">
        <Group colGap={8}>
          <h5 className="metamask-color">Add Token</h5>
          {/* <Group colGap={6} alignItems="center">
            <img src="/images/logos/signet.svg" height={44} width={44} />
            <img src="/images/icons/plus.svg" />
          </Group> */}
          <img src="/images/logos/metamask.svg" width={40} height={40} />
        </Group>
        <Button as="button" onClick={() => addToken.call()} className="w-xs-100">
          <div className="metamask-button">
            <Group fullSize justifyContent="center">
              <p className="uppercase metamask-color button-text">{`Add ${TOKEN_TICKER_TOKEN} to metamask`}</p>
            </Group>
          </div>
        </Button>
      </Stack>
    </HelpingCard>
  );
};

const Trade = () => {
  return (
    <HelpingCard className="h-xs-100 trade disabled" style={{ width: "100%", maxWidth: "430.5px" }}>
      <Stack fullSize justifyContent="space-between" alignItems="center">
        <h5 className="w-xs-100 uniswap-color">Trade</h5>
        <img src="/images/logos/uniswap-pink.svg" height={120} />
        <Button as="a" href={UNISWAP_URL} className="w-xs-100">
          <div className="uniswap-button">
            <Group fullSize justifyContent="center">
              <p className="uppercase uniswap-color button-text">trade on uniswap</p>
            </Group>
          </div>
        </Button>
      </Stack>
    </HelpingCard>
  );
};

const useUtilitiesData = () => {
  const data = [
    {
      img: "/images/pages/Investors/info/ut-1.png",
      tagline: "Token Utility",
      title: "Revenue Share",
      description: "Earn from platform operations and B2B services."
    },
    {
      img: "/images/pages/Investors/info/ut-2.png",
      tagline: "Token Utility",
      title: "AI Tools",
      description: "Access cutting-edge recruitment solutions."
    },
    {
      img: "/images/pages/Investors/info/ut-3.png",
      tagline: "Token Utility",
      title: "Marketplace",
      description: "Unlock exclusive job opportunities."
    },
    {
      img: "/images/pages/Investors/info/ut-4.png",
      tagline: "Token Utility",
      title: "Platform Operations",
      description: `Use the ${TOKEN_TICKER_TOKEN} to power premium services.`
    },
    {
      img: "/images/pages/Investors/info/ut-4.png",
      tagline: "Token Utility",
      title: "Governance",
      description: "Vote on platform development and updates."
    }
  ];

  return data;
};

const Utilities = () => {
  const data = useUtilitiesData();

  return (
    <Group fullWidth colGap={BOXES_GAP} rowGap={BOXES_GAP} alignItems="stretch" className="utilities">
      {data.map((d, i) => (
        <HelpingCard style={{ width: "100%", maxWidth: "233px", minWidth: "185px" }} key={i} className="default">
          <Stack fullHeight rowGap={24}>
            <img src={d.img} width={80} height={80} />
            <Stack fullWidth rowGap={8}>
              {/* <p className="label semi-bold">{d.tagline}</p> */}
              <h5>{d.title}</h5>
              <p className="description">{d.description}</p>
            </Stack>
          </Stack>
        </HelpingCard>
      ))}
    </Group>
  );
};
