import { UseFileInput, useFileInput } from "@library/frontend/hooks/useFileInput";
import { useValidateFile } from "@library/frontend/hooks/useValidateFile";

export interface CompleteFileReturn {
  fileInput: UseFileInput;
  onChangeFile(e: React.ChangeEvent<HTMLInputElement>): void;
  onDropFile: (e: React.DragEvent<HTMLDivElement>) => void;
  isCorrect: boolean;
  errors: string[];
}

const useCompleteFile = (): CompleteFileReturn => {
  const fileInput = useFileInput();
  const verificator = useValidateFile({ allowedExtensions: ["pdf"], maxSizeInMb: 1 });

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) return;

    verificator.validate(file);
    fileInput.update(file);
  };

  const onDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const droppedFiles = e.dataTransfer.files;
    if (droppedFiles.length === 0) return;

    const droppedFile = droppedFiles[0];
    if (!droppedFile) return;

    verificator.validate(droppedFile);
    fileInput.update(droppedFile);
  };

  const isCorrect = verificator.validationResult.isValid;
  const errors = verificator.validationResult.errors;

  return { fileInput, onChangeFile, onDropFile, isCorrect, errors };
};

export default useCompleteFile;
