import { screenSizes } from "../constants/screen";
import { ScreenSizes } from "../interfaces/ScreenSizes";
import { useWindowDimensions } from "./useWindowDimensions";

const useIsMobile = (maxScreenSize: ScreenSizes) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= screenSizes[maxScreenSize];

  return isMobile;
};

export default useIsMobile;
