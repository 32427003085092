import { useEffect, useRef, useState } from "react";

export const useIntervalCallback = (intervals: number[], callback: () => void) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const savedCallback = useRef<() => void>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!isRunning || intervals.length === 0 || currentIndex >= intervals.length) return;

    const tick = () => {
      savedCallback.current();

      if (currentIndex === intervals.length - 1) {
        setIsRunning(false); // Stop the interval after the last step
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1); // Move to the next step
      }
    };

    const id = setInterval(tick, intervals[currentIndex]);

    return () => clearInterval(id);
  }, [isRunning, currentIndex, intervals]);

  const start = () => {
    setIsRunning(true);
  };

  const reset = () => {
    setIsRunning(false); // Stop the running interval
    setCurrentIndex(0); // Reset index to the start
  };

  return { start, reset, isRunning };
};
