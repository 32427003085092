import PaddingSectionTemplate from "src/app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";

import Group from "@library/frontend/design-system/Group/Group";
import Stack from "@library/frontend/design-system/Stack/Stack";

import useIsMobile from "@library/frontend/hooks/useIsMobile";

import Button from "@library/frontend/design-system/Button/Button";
import { QUBE_APP_URL } from "@integrations/external-links";

import "./Hero.scss";

const Hero = () => {
  const isMobile = useIsMobile("xl");
  const isMobileMd = useIsMobile("md");

  return (
    <section className="home-hero">
      <div className="background">
        <PaddingSectionTemplate>
          <Group fullSize className="relative" justifyContent={isMobile ? "center" : "flex-start"}>
            <Stack
              rowGap={16}
              style={{ maxWidth: "600px" }}
              className="relative"
              fullSize
              justifyContent={isMobileMd ? "flex-end" : "center"}
              alignItems={isMobileMd ? "center" : "flex-start"}
            >
              <h1 className="title t-xs-c t-md-l">
                <span className="basic-color">Combining </span>
                <span className="highlighted">AI </span>
                <span className="basic-color">and </span>
                <span className="highlighted">Blockchain </span>
                <span className="basic-color">to Revolutionize HR</span>
              </h1>
              <p className="description t-xs-c t-md-l">
                Building a platform for Hiring 5.0, leveraging AI agents and blockchain to redefine recruitment
                processes. The ultimate tool for HR departments and recruiters.
              </p>
              <Button
                as="a"
                href={QUBE_APP_URL}
                target="_blank"
                className="sign-up w-xs-100 w-sm-mc"
                style={{ minWidth: "200px" }}
              >
                <Group colGap={8} justifyContent="center" fullSize>
                  <p className="label">sign up</p>
                  <img src="/images/icons/avatar-white.svg" />
                </Group>
              </Button>
            </Stack>
          </Group>
        </PaddingSectionTemplate>
      </div>
    </section>
  );
};

export default Hero;
