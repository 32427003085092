import Stack from "@library/frontend/design-system/Stack/Stack";
import Group from "@library/frontend/design-system/Group/Group";
import PaddingSectionTemplate from "../../templates/PaddingSectionTemplate/PaddingSectionTemplate";

import Button from "@library/frontend/design-system/Button/Button";
import { LoginPlatformBtn } from "../Navbar/components/LoginPlatformBtn";
import { ForInvestorsBtn } from "../Navbar/components/ForInvestorsBtn";
import { TELEGRAM_URL, X_URL } from "@app/project/socials";
import useIsMobile from "@library/frontend/hooks/useIsMobile";

import { GITBOOK_URL } from "@integrations/external-links";

import "./Footer.scss";

const socials = [
  {
    img: "/images/socials/x-white.svg",
    url: X_URL
  },
  {
    img: "/images/socials/telegram-white.svg",
    url: TELEGRAM_URL
  }
];

export const Footer = () => {
  const isMobile = useIsMobile("md");

  return (
    <footer className="footer">
      <PaddingSectionTemplate>
        <Stack fullWidth rowGap={40}>
          <Group fullWidth justifyContent="space-between" alignItems="flex-start">
            <img src="/images/logos/logo-darkmode.svg" />
            <Group colGap={24} rowGap={16} className="wrap-reverse" justifyContent="flex-end">
              <Litepaper />
              <Socials />
            </Group>
          </Group>
          <Group
            justifyContent={isMobile ? "center" : "space-between"}
            fullWidth
            colGap={16}
            rowGap={24}
            className="wrap"
          >
            <Group colGap={16} rowGap={8} className="wrap w-xs-100 w-md-mc">
              <LoginPlatformBtn className="w-xs-100 w-md-mc" />
              <ForInvestorsBtn className="w-xs-100 w-md-mc" />
            </Group>
            <p className="white-0 t-xs-c address" style={{ maxWidth: "280px" }}>
              Registration number: 3975 LLC 2025 Euro House, Richmond Hill Road, P.O. Box 2897, Kingstown, St Vincent
              and the Grenadines
            </p>
            <p className="rights">{new Date().getFullYear()} @ QubeCV. ALl rights reserved</p>
          </Group>
        </Stack>
      </PaddingSectionTemplate>
    </footer>
  );
};

const Litepaper = () => {
  return (
    <Group colGap={16} className="disabled">
      <p className="primary-100 address capitalize">{"litepaper (coming soon) >"}</p>
      <Button as="a" target="_blank" rel="noreferrer noopener nofollow" href={GITBOOK_URL}>
        <img src="/images/logos/gitbook.svg" />
      </Button>
    </Group>
  );
};

const Socials = () => {
  return (
    <Group colGap={8}>
      <p className="primary-100 address capitalize">{"check our socials >"}</p>
      <Group colGap={16}>
        {socials.map((s, id) => (
          <Button as="a" target="_blank" rel="noreferrer noopener nofollow" href={s.url} key={id}>
            <img src={s.img} />
          </Button>
        ))}
      </Group>
    </Group>
  );
};
