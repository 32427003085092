import { useStraightStateMachine } from "@library/frontend/hooks/useStraightStateMachine";
import { StepsKeys } from "./Verify.ctx";
import { useIntervalCallback } from "@library/frontend/hooks/useIntervalCallback";
import useBoolean from "@library/frontend/hooks/useBoolean";

const uploadingScenario: Record<string, number> = {
  "uploading-cv": 2000,
  "checking-on-blockchain": 2000
};

const steps: StepsKeys[] = ["uploading-cv", "checking-on-blockchain", "result"];

const intervals = steps.filter((el) => !!uploadingScenario[el]).map((el) => uploadingScenario[el]);

const useVerifyFile = () => {
  const [isMachineRunning, on, off] = useBoolean(false);
  const stateMachine = useStraightStateMachine(steps);

  const interval = useIntervalCallback(intervals, stateMachine.next);

  const start = () => {
    interval.start();
    on();
  };

  const reset = () => {
    interval.reset();
    stateMachine.reset();
    off();
  };

  const isVerifying = stateMachine.step !== "none" && isMachineRunning;
  const verifyingStep = stateMachine.step as StepsKeys;

  return { start, reset, isVerifying, verifyingStep };
};

export default useVerifyFile;
