import Portal from "@library/frontend/design-system/Portal/Portal";
import { useVerifyCtx } from "./Verify.ctx";
import ShowSmooth from "@library/frontend/design-system/ShowSmooth/ShowSmooth";
import { Background } from "./Background";
import Positioner from "@library/frontend/design-system/Positioner/Positioner";
import { Modal } from "./Modal";
import Show from "@library/frontend/design-system/Show/Show";

export const VerifyComponents = () => {
  const { isBeenVerifying } = useVerifyCtx();

  return (
    <Show when={isBeenVerifying}>
      <Portal id={"overlay"}>
        <ShowSmooth onlyWhen={isBeenVerifying} timingMs={300}>
          <Background />
          <div style={{ position: "fixed", left: "0px", top: "0px", width: "100vw", height: "100vh" }}>
            <Positioner xCoord={"center"} yCoord={"center"}>
              <Modal />
            </Positioner>
          </div>
        </ShowSmooth>
      </Portal>
    </Show>
  );
};
