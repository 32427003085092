import { useRef, useState, useCallback } from "react";

export interface UseFileInput {
  value: File | null;
  ref: React.RefObject<HTMLInputElement>;
  update(file: File | null): void;
  reset: VoidFunction;
  open: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

export const useFileInput = (): UseFileInput => {
  const [value, setValue] = useState<File | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  const update = (file: File | null) => setValue(file);

  const reset = useCallback(() => {
    update(null);

    if (!ref.current) return;

    ref.current.value = "";
  }, []);

  const open = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    console.log("open");

    e.preventDefault();

    if (!ref.current) return;

    ref.current.click();
  };

  return {
    value,
    ref,
    update,
    reset,
    open
  };
};
