import { useState } from "react";

const useString = <T extends string>(initial: T) => {
  const [value, setValue] = useState<T>(initial);
  const update = (newAdd: T) => setValue(newAdd);
  const reset = () => setValue(initial);

  return { value, update, reset };
};

export default useString;
