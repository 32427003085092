import Stack from "@library/frontend/design-system/Stack/Stack";
import { TELEGRAM_URL, X_URL } from "@app/project/socials";
import Group from "@library/frontend/design-system/Group/Group";
import Button from "@library/frontend/design-system/Button/Button";

import { Logo } from "../Logo";
import { CloseMenu } from "./CloseMenu";
import { X } from "./X";

import { LoginPlatformBtn } from "../LoginPlatformBtn";
import { ForInvestorsBtn } from "../ForInvestorsBtn";

import { classNames } from "@library/tools/utils";
import { useMobileMenuCtx } from "./MenuMobile.ctx";

import "./MenuMobile.scss";

export const MenuMobile = () => {
  const mobileMenu = useMobileMenuCtx();
  return (
    <div className={classNames("menu-mobile", mobileMenu.isOpen ? "open" : "")}>
      <Stack justifyContent="space-between" fullSize>
        <Top />
        <Bottom />
      </Stack>
    </div>
  );
};

const Top = () => {
  return (
    <Group fullWidth justifyContent="space-between">
      <CloseMenu>
        <Logo />
      </CloseMenu>
      <X />
    </Group>
  );
};

const Bottom = () => {
  return (
    <Stack fullWidth rowGap={40}>
      <Socials />
      <Buttons />
    </Stack>
  );
};

const socials = [
  {
    img: "/images/socials/x-dark.svg",
    url: X_URL
  },
  {
    img: "/images/socials/telegram-dark.svg",
    url: TELEGRAM_URL
  }
];

const Socials = () => {
  return (
    <Stack fullWidth rowGap={24} alignItems="center">
      <p>Check our socials</p>
      <Group colGap={8} fullWidth>
        {socials.map((s, id) => (
          <CloseMenu className="w-xs-100" key={id}>
            <Button as="a" href={s.url} className="social-btn" key={id}>
              <img src={s.img} width={26} height={26} />
            </Button>
          </CloseMenu>
        ))}
      </Group>
    </Stack>
  );
};

const Buttons = () => {
  return (
    <Stack fullWidth rowGap={8}>
      <CloseMenu className="w-xs-100">
        <LoginPlatformBtn />
      </CloseMenu>
      <CloseMenu className="w-xs-100">
        <ForInvestorsBtn className="w-xs-100" />
      </CloseMenu>
    </Stack>
  );
};
