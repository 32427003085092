import { MetaMaskProvider } from "metamask-react";

import { paths } from "@app/frontend/routing/paths";
import { Footer } from "../../components/Footer/Footer";
import { Navbar } from "../../components/Navbar/Navbar";
import { Hero } from "./sections/Hero/Hero";
import { Info } from "./sections/Info/Info";
import { Roadmap } from "./sections/Roadmap/Roadmap";

import { MetamaskFacadeProvider } from "@app/domain/Metamask/MetamaskFacade.ctx";

import "./Investors.scss";

export const Investors = () => {
  return (
    <div className="investors">
      <Navbar />
      <Hero />
      <Info />
      <Roadmap />
      <Footer />
    </div>
  );
};

export default {
  path: paths.investors,
  element: (
    <MetaMaskProvider>
      <MetamaskFacadeProvider>
        <Investors />
      </MetamaskFacadeProvider>
    </MetaMaskProvider>
  ),
  children: []
};
