import { useMetamaskFacadeCtx } from "./MetamaskFacade.ctx";
import { MetaMaskState } from "metamask-react/lib/metamask-context";

const addTokenSteps = ["no-metamask", "metamask-not-connected", "metamask-connected"] as const;
type AddTokenSteps = (typeof addTokenSteps)[number];

const mapStatusToTokenSteps: Record<MetaMaskState["status"], AddTokenSteps> = {
  unavailable: "no-metamask",
  notConnected: "metamask-not-connected",
  connected: "metamask-connected",
  connecting: "metamask-not-connected",
  initializing: "metamask-not-connected"
};

interface AddTokenReturn {
  call: Function;
}

export const useAddTokenSaga = (): AddTokenReturn => {
  const { metamaskFacade } = useMetamaskFacadeCtx();
  const status = metamaskFacade.status;

  const actionPerStep: Record<AddTokenSteps, Function> = {
    "no-metamask": () => console.log("install metamask first"),
    "metamask-not-connected": () => metamaskFacade.connect(),
    "metamask-connected": () => metamaskFacade.addToken()
  };
  const mapItem = mapStatusToTokenSteps[status];

  const call = actionPerStep[mapItem];

  return { call };
};
