import PaddingSectionTemplate from "@app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Stack from "@library/frontend/design-system/Stack/Stack";
import Group from "@library/frontend/design-system/Group/Group";
import { HelpingCard } from "@app/frontend/view/components/Card/HelpingCard";
import Button from "@library/frontend/design-system/Button/Button";

import useIsMobile from "@library/frontend/hooks/useIsMobile";
import { useVerifyCtx, VerifyProvider } from "./module/Verify.ctx";
import { classNames } from "@library/tools/utils";

import "./Verify.scss";

export const Verify = () => {
  const isMobile = useIsMobile("md");
  return (
    <section className="verify">
      <PaddingSectionTemplate>
        <Stack fullWidth rowGap={24}>
          <Stack fullWidth rowGap={8}>
            <h5 className="t-xs-c w-xs-100">Verify CVs with Blockchain</h5>
            <p className="t-xs-c m-xs-a" style={{ maxWidth: "600px" }}>
              Instantly verify the authenticity of CVs and ensure accuracy in employee credentials. A powerful solution
              for HR teams and recruiters.
            </p>
          </Stack>
          <Group fullWidth colGap={8} rowGap={8} className={isMobile ? "wrap" : ""} alignItems="stretch">
            <VerifyProvider>
              <Upload />
              <EnterCode />
            </VerifyProvider>
          </Group>
        </Stack>
      </PaddingSectionTemplate>
    </section>
  );
};

const uploadDescriptionDependingOnMobility = {
  desktop: "Drop or upload your CV (PDF)",
  mobile: "(PDF)"
};

const Upload = () => {
  const { completeFile } = useVerifyCtx();
  const isMobile = useIsMobile("lg");

  const mobility = isMobile ? "mobile" : "desktop";
  const description = uploadDescriptionDependingOnMobility[mobility];

  return (
    <HelpingCard
      className="w-xs-100 card upload"
      onDragOver={(e) => e.preventDefault()}
      onDrop={completeFile.onDropFile}
    >
      <Stack fullWidth rowGap={24}>
        <img src="/images/graphics/upload.svg" />
        <Stack rowGap={8} fullWidth>
          <h6 className="card-title">{description}</h6>
        </Stack>
        <Stack fullWidth rowGap={4}>
          <label htmlFor="file-upload" className="custom-input" onClick={completeFile.fileInput.open}>
            <Button as="button" onClick={() => {}} className="w-xs-100">
              <Group className="primary-btn w-xs-i" justifyContent="center">
                <p className="label">Upload file</p>
              </Group>
            </Button>
          </label>
          {!completeFile.isCorrect && <p className="error danger">{completeFile.errors[0]}</p>}
        </Stack>
      </Stack>
      <input
        id="file-upload"
        type="file"
        accept=".pdf"
        ref={completeFile.fileInput.ref}
        className="default-input"
        onChange={completeFile.onChangeFile}
      />
    </HelpingCard>
  );
};

const buttonText = {
  completeFirst: "Insert code first",
  ok: "Verify CV Code"
};

const EnterCode = () => {
  const { verifyByCode, completeCode } = useVerifyCtx();
  const isMobile = useIsMobile("sm-l");

  return (
    <HelpingCard className="w-xs-100 card">
      <Stack fullWidth rowGap={24}>
        <img src="/images/graphics/enter-code.svg" />
        <Stack rowGap={8} fullWidth>
          <h6 className="card-title">Enter the unique CV code for instant verification</h6>
        </Stack>
        <Stack fullWidth rowGap={8}>
          <Group fullWidth colGap={8} rowGap={8} className={isMobile ? "wrap" : ""}>
            <input
              className="input w-xs-100"
              placeholder="Insert Your CV Code"
              value={completeCode.value}
              onChange={(e) => completeCode.changeCode(e.currentTarget.value)}
            />
            <Button
              as="button"
              onClick={verifyByCode}
              className={classNames("w-xs-100", !completeCode.isCorrect ? "disabled" : "")}
              disabled={!completeCode.isCorrect}
            >
              <Group className="primary-btn" justifyContent="center">
                <p className="label">{buttonText[completeCode.isCorrect ? "ok" : "completeFirst"]}</p>
              </Group>
            </Button>
          </Group>
          {!completeCode.isCorrect && !completeCode.isEmpty && <p className="error danger">{completeCode.errors[0]}</p>}
        </Stack>
      </Stack>
    </HelpingCard>
  );
};
