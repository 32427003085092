import React, { useState } from "react";

import { IChildren } from "@library/frontend/interfaces/IChildren";
import { EXPLAINER_1, EXPLAINER_2, EXPLAINER_3 } from "@integrations/external-links";

interface IExplainersCtx {
  vidId: VideoKeyOrNull;
  selectVideo: (vidId: ExplainersKeys) => void;
  resetVideo: () => void;
  currentVideo: VideoKeyOrNull;
}

const ExplainersCtx = React.createContext(null as any);

const explainersKeys = ["1", "2", "3"] as const;

export type ExplainersKeys = (typeof explainersKeys)[number];

const explainersVideos: Record<ExplainersKeys, string> = {
  "1": EXPLAINER_1,
  "2": EXPLAINER_2,
  "3": EXPLAINER_3
};

type VideoKeyOrNull = null | ExplainersKeys;

export const ExplainersProvider = ({ children }: IChildren) => {
  const [vidId, updateVidId] = useState<VideoKeyOrNull>(null);

  const selectVideo = (vidId: ExplainersKeys) => updateVidId(vidId);
  const resetVideo = () => updateVidId(null);

  const currentVideo = explainersVideos[vidId as ExplainersKeys] || null;

  return (
    <ExplainersCtx.Provider value={{ vidId, selectVideo, resetVideo, currentVideo }}>{children}</ExplainersCtx.Provider>
  );
};

export const useExplainersCtx = (): IExplainersCtx => React.useContext(ExplainersCtx);
