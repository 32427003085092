import { useState, useCallback } from "react";

interface UseValidateFileProps {
  allowedExtensions?: string[];
  maxSizeInMb?: number;
  minSizeInMb?: number;
  customValidator?: (file: File) => boolean;
}

interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

interface UseValidateFileResult {
  validate: (file: File) => ValidationResult;
  validationResult: ValidationResult;
}

export const useValidateFile = ({
  allowedExtensions,
  maxSizeInMb,
  minSizeInMb,
  customValidator
}: UseValidateFileProps): UseValidateFileResult => {
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    isValid: false,
    errors: []
  });

  const validate = useCallback(
    (file: File): ValidationResult => {
      const errors: string[] = [];

      if (!file) {
        errors.push("File is required and cannot be empty.");
      }

      const maxSizeInBytes = maxSizeInMb ? maxSizeInMb * 1024 * 1024 : undefined;
      const minSizeInBytes = minSizeInMb ? minSizeInMb * 1024 * 1024 : undefined;

      if (allowedExtensions) {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
          errors.push(`File type not allowed. Allowed extensions: ${allowedExtensions.join(", ")}.`);
        }
      }

      if (maxSizeInBytes !== undefined && file.size > maxSizeInBytes) {
        errors.push(`File size exceeds the maximum limit of ${maxSizeInMb} MB.`);
      }

      if (minSizeInBytes !== undefined && file.size < minSizeInBytes) {
        errors.push(`File size is less than the minimum limit of ${minSizeInMb} MB.`);
      }

      if (customValidator && !customValidator(file)) {
        errors.push(`File does not meet the custom validation criteria.`);
      }

      const isValid = errors.length === 0;

      const result = { isValid, errors };
      setValidationResult(result);
      return result;
    },
    [allowedExtensions, maxSizeInMb, minSizeInMb, customValidator]
  );

  return { validate, validationResult };
};
