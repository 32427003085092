import { formatNumberToString } from "@library/tools/utils";

export const TOKEN_ADDRESS = "Coming soon";
export const TOKEN_TICKER_REAL = "---";
export const TOKEN_TICKER_TOKEN = "token";
export const TOKEN_TICKER_EMPTY = "";
export const TOKEN_DECIMALS = 18;
export const TOKEN_IMAGE = `${window.location.origin}/images/logos/token-image.png`;
export const TOKEN_TOTAL_SUPPLY_NUMBER = 1_000_000_000;
export const TOKEN_TOTAL_SUPPLY_FORMATTED = formatNumberToString(TOKEN_TOTAL_SUPPLY_NUMBER, ",");
