import React from "react";
import { useWindowDimensions } from "./useWindowDimensions";

const useBlockScroll = () => {
  const { width } = useWindowDimensions();

  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "initial";
    };
  }, [width]);
};

export default useBlockScroll;
