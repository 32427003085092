import PaddingSectionTemplate from "@app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Stack from "@library/frontend/design-system/Stack/Stack";

import Group from "@library/frontend/design-system/Group/Group";
import Button from "@library/frontend/design-system/Button/Button";
import { UniswapButtonStyle } from "@app/frontend/view/templates/UniswapButtonStyle/UniswapButtonStyle";
import { MetamaskButtonStyle } from "@app/frontend/view/templates/MetamaskButtonStyle/MetamaskButtonStyle";

import useIsMobile from "@library/frontend/hooks/useIsMobile";

import { UNISWAP_URL } from "@app/project/exchanges";
import { TOKEN_TICKER_TOKEN } from "@integrations/qcv-token";
import { useAddTokenSaga } from "@app/domain/Metamask/addTokenSaga";

import "./Hero.scss";

export const Hero = () => {
  const isMobile = useIsMobile("md");

  return (
    <section className="investors-hero">
      <div className="background">
        <PaddingSectionTemplate>
          <Stack rowGap={16} style={{ maxWidth: "580px" }} fullSize justifyContent={isMobile ? "flex-end" : "center"}>
            <p className="tagline basic-color t-xs-c t-lg-l w-xs-100">The First Platform</p>
            <h1 className="title t-xs-c t-lg-l">
              <span className="basic-color">Shaping </span>
              <span className="highlighted">Hiring 5.0 </span>
              <span className="basic-color">with </span>
              <span className="highlighted">AI </span>
              <span className="basic-color">and </span>
              <span className="highlighted">Blockchain </span>
            </h1>
            {/* <Group colGap={8} className="buttons">
              <UniswapButton />
              <MetamaskButton />
            </Group> */}
          </Stack>
        </PaddingSectionTemplate>
      </div>
    </section>
  );
};

const UniswapButton = () => {
  return (
    <Button as="a" href={UNISWAP_URL} className="disabled">
      <UniswapButtonStyle>
        <Group fullSize colGap={8}>
          <p className="uniswap-label label">trade on uniswap</p>
          <img src="/images/logos/uniswap-pink.svg" width={32} height={32} />
        </Group>
      </UniswapButtonStyle>
    </Button>
  );
};

const MetamaskButton = () => {
  const addToken = useAddTokenSaga();
  return (
    <Button as="button" onClick={() => addToken.call()} className="disabled">
      <MetamaskButtonStyle>
        <Group fullSize colGap={8}>
          <p className="metamask-label label">{`add ${TOKEN_TICKER_TOKEN} to metamask`}</p>
          <img src="/images/logos/metamask.svg" width={32} height={32} />
        </Group>
      </MetamaskButtonStyle>
    </Button>
  );
};
