import PaddingSectionTemplate from "@app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Stack from "@library/frontend/design-system/Stack/Stack";

import Group from "@library/frontend/design-system/Group/Group";
import { HelpingCard } from "@app/frontend/view/components/Card/HelpingCard";

import "./Reasons.scss";
import Flex from "@library/frontend/design-system/Flex/Flex";

const cards = [
  {
    img: "/images/pages/Home/reasons/5.svg",
    tagline: "Advanced Tools:",
    title: "AI Agents",
    description: "Seamlessly integrate into your hiring process to avoid fraudulent CVs"
  },
  {
    img: "/images/pages/Home/reasons/2.svg",
    tagline: "Advanced Tools:",
    title: "Instant CV Verification",
    description: "Verify a CV by uploading it in seconds."
  },
  {
    img: "/images/pages/Home/reasons/3.svg",
    tagline: "Advanced Tools:",
    title: "Employment History",
    description: "Create a secure, verified employment history for future use."
  },
  {
    img: "/images/pages/Home/reasons/4.svg",
    tagline: "Talent Scoring",
    title: "Talent Scoring",
    description: "AI evaluates candidate skills and potential with precision."
  },
  {
    img: "/images/pages/Home/reasons/1.svg",
    tagline: "Talent Acquisition",
    title: "Talent Acquisition",
    description: "Making talent discovery fast and simple."
  }
];

export const Reasons = () => {
  return (
    <section className="reasons">
      <PaddingSectionTemplate>
        <Stack fullWidth rowGap={24}>
          <Stack fullWidth rowGap={8} alignItems="center">
            <h4 className="t-xs-c">Why QubeCV AI?</h4>
            <p className="t-xs-c" style={{ maxWidth: "600px" }}>
              Driving innovation in hiring with AI, AI agents, and blockchain. Delivering trust, efficiency, and a
              transformative platform for the future of recruitment.
            </p>
          </Stack>
          <Group fullWidth colGap={8} rowGap={8} justifyContent="center" alignItems="stretch" className="wrap">
            {cards.map((c, id) => (
              <HelpingCard className="card" key={id}>
                <Flex baseRole={"stack"} mobileBreakpoint={"sm-l"} rowGap={24} colGap={24} fullWidth>
                  <img src={c.img} width={80} height={80} />
                  <Stack fullWidth rowGap={8}>
                    {/* <p>{c.tagline}</p> */}
                    <h5>{c.title}</h5>
                    <p>{c.description}</p>
                  </Stack>
                </Flex>
              </HelpingCard>
            ))}
          </Group>
        </Stack>
      </PaddingSectionTemplate>
    </section>
  );
};
