import Show from "@library/frontend/design-system/Show/Show";
import { ModalContentVerifying } from "./ModalContentVerifying";
import { StepsKeys, useVerifyCtx } from "./Verify.ctx";
import { ModalContentResult } from "./ModalContentResult";

import "./Modal.scss";

type Extracted = Extract<StepsKeys, "uploading-cv" | "checking-on-blockchain" | "sending-code">;
const extracted: Extracted[] = ["uploading-cv", "checking-on-blockchain", "sending-code"];

const titles: Record<Extracted, string> = {
  "uploading-cv": "Uploading CV...",
  "checking-on-blockchain": "Checking on Blockchain...",
  "sending-code": "Sending Code..."
};

export const Modal = () => {
  const { verifyingStep } = useVerifyCtx();
  const title = titles[verifyingStep as Extracted];

  const stepIsMatching = extracted.includes(verifyingStep as any);

  return (
    <div className="verify-modal">
      <Show when={stepIsMatching}>
        <ModalContentVerifying title={title} />
      </Show>
      <Show when={verifyingStep === "result"}>
        <ModalContentResult />
      </Show>
    </div>
  );
};
