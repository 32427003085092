import React from "react";

import { IChildren } from "@library/frontend/interfaces/IChildren";
import { MetaMaskState } from "metamask-react/lib/metamask-context";
import { useMetaMask } from "metamask-react/lib/use-metamask";
import { TOKEN_ADDRESS, TOKEN_DECIMALS, TOKEN_IMAGE, TOKEN_TICKER_REAL } from "@integrations/qcv-token";

interface IMetamaskFacadeCtx {
  metamaskFacade: UseMetamaskFacadeReturn;
}

const MetamaskFacadeCtx = React.createContext(null as any);

export const MetamaskFacadeProvider = ({ children }: IChildren) => {
  const metamaskFacade = useMetamaskFacade();

  return <MetamaskFacadeCtx.Provider value={{ metamaskFacade }}>{children}</MetamaskFacadeCtx.Provider>;
};

export const useMetamaskFacadeCtx = (): IMetamaskFacadeCtx => React.useContext(MetamaskFacadeCtx);

interface Token {
  address: string;
  symbol: string;
  decimals: number;
  image: string;
}

const addTokenToMetamask = async (token: Token) => {
  const ethereum = (window as any).ethereum;
  if (!ethereum) {
    console.log("Metamask is not installed");
    return;
  }

  try {
    await ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: token
      }
    });
  } catch (error) {
    console.log(error);
  }
};

type Status = MetaMaskState["status"];

interface UseMetamaskFacadeReturn {
  connect(): Promise<void>;
  addToken(): Promise<void>;
  status: Status;
}

const QCV_TOKEN: Token = {
  address: TOKEN_ADDRESS,
  decimals: TOKEN_DECIMALS,
  symbol: TOKEN_TICKER_REAL,
  image: TOKEN_IMAGE
};

const useMetamaskFacade = (): UseMetamaskFacadeReturn => {
  const { connect: connectMetamask, status } = useMetaMask();

  const connect = async () => {
    try {
      await connectMetamask();
    } catch (e) {
      console.log(e);
    }
  };
  const addToken = async () => await addTokenToMetamask(QCV_TOKEN);

  return { connect, addToken, status };
};
