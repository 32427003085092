import PaddingSectionTemplate from "src/app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Stack from "@library/frontend/design-system/Stack/Stack";
import Group from "@library/frontend/design-system/Group/Group";

import "./SupportedBy.scss";

const supporters = [
  {
    img: "/images/logos/gemini.png"
  },
  {
    img: "/images/logos/chat-gpt.svg"
  },
  {
    img: "/images/logos/claude.png"
  },
  {
    img: "/images/pages/Home/supported/agent.svg"
  },
  {
    img: "/images/pages/Home/supported/engine.svg"
  },
  {
    img: "/images/pages/Home/supported/api.svg"
  }
];

const SupportedBy = () => {
  return (
    <section className="supported-by">
      <PaddingSectionTemplate>
        <Stack rowGap={32} fullWidth>
          <p className="title w-xs-100 t-xs-c uppercase primary-1000">Supported by</p>
          <Group colGap={40} rowGap={24} fullWidth justifyContent={"center"} alignItems="center" className="wrap">
            {supporters.map((s, i) => (
              <img src={s.img} key={i} />
            ))}
          </Group>
        </Stack>
      </PaddingSectionTemplate>
    </section>
  );
};

export default SupportedBy;
