import Button from "@library/frontend/design-system/Button/Button";
import { useMobileMenuCtx } from "./MenuMobile.ctx";

export const Hamburger = () => {
  const mobileMenu = useMobileMenuCtx();
  return (
    <div className="hamburger">
      <Button as="button" onClick={mobileMenu.toggleOpen}>
        <img src="/images/icons/hamburger.svg" />
      </Button>
    </div>
  );
};
