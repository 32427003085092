import { useEffect, useState } from "react";

type UseStepperProps = {
  stepsQuantity: number;
  countNextEveryMs: number;
};

type UseStepperReturn = {
  currentStep: number;
  totalSteps: number;
};

export const useStepper = ({ stepsQuantity, countNextEveryMs }: UseStepperProps): UseStepperReturn => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (currentStep >= stepsQuantity) {
      return; // Stop incrementing when the last step is reached
    }

    const interval = setInterval(() => {
      setCurrentStep((prevStep) => {
        const nextStep = prevStep + 1;
        if (nextStep >= stepsQuantity) {
          clearInterval(interval); // Clear the interval when the last step is reached
        }
        return nextStep;
      });
    }, countNextEveryMs);

    return () => clearInterval(interval); // Cleanup interval on component unmount or step change
  }, [currentStep, countNextEveryMs, stepsQuantity]);

  return {
    currentStep,
    totalSteps: stepsQuantity
  };
};
