import Stack from "@library/frontend/design-system/Stack/Stack";
import Group from "@library/frontend/design-system/Group/Group";
import Button from "@library/frontend/design-system/Button/Button";
import { useVerifyCtx } from "./Verify.ctx";

export const ModalContentResult = () => {
  const { resetVerifying } = useVerifyCtx();
  return (
    <Stack className="modal-content-result" alignItems="center" rowGap={24}>
      <img src="/images/icons/not-found.svg" />
      <h3 className="title">No CV found on blockchain</h3>
      <p className="description">Resume not found in blockchain records.</p>
      <Group colGap={16} justifyContent="center">
        {/* <Button as="button" onClick={resetVerifying} className="action-button upload">
          <Group fullSize justifyContent="center">
            <p>Upload new cv</p>
          </Group>
        </Button> */}
        <Button as="button" onClick={resetVerifying} className="action-button upload">
          <Group fullSize justifyContent="center">
            <p>Close</p>
          </Group>
        </Button>
      </Group>
    </Stack>
  );
};
