import { Link } from "react-router-dom";
import { paths } from "@app/frontend/routing/paths";

export const Logo = () => {
  return (
    <Link to={paths.home}>
      <img src="/images/logos/logo.svg" style={{ height: "40px" }} />
    </Link>
  );
};
