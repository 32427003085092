import { paths } from "src/app/frontend/routing/paths";

import { Navbar } from "../../components/Navbar/Navbar";
import { Footer } from "../../components/Footer/Footer";

import Hero from "./sections/Hero/Hero";

import MediaAboutUs from "./sections/MediaAboutUs/MediaAboutUs";
import { Explainers } from "./sections/Explainers/Explainers.public";
import SupportedBy from "./sections/SupportedBy/SupportedBy";

import { Verify } from "./sections/Verify/Verify";
import { Reasons } from "./sections/Reasons/Reasons";
import { LogInWith } from "./sections/LogInWith/LogInWith";

import "./Home.scss";
import { Panel } from "./sections/Panel/Panel";

const Home = () => {
  return (
    <div className="home">
      <Navbar />
      <Hero />
      <SupportedBy />
      <Explainers />
      <Verify />
      <MediaAboutUs />
      <Reasons />
      <Panel />
      {/* <LogInWith /> */}
      <Footer />
    </div>
  );
};

export default {
  path: paths.home,
  element: <Home />,
  children: []
};
