import { IChildren } from "@library/frontend/interfaces/IChildren";

import { classNames } from "@library/tools/utils";
import { BaseProps } from "@library/frontend/interfaces/BaseProps";

import "./HelpingCard.scss";

export const HelpingCard = ({ children, className, style, ...rest }: IChildren & BaseProps) => {
  return (
    <div className={classNames("helping-card", className)} style={style} {...rest}>
      {children}
    </div>
  );
};
