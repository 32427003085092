import Group from "@library/frontend/design-system/Group/Group";
import DesktopOnly from "@library/frontend/design-system/DesktopOnly/DesktopOnly";
import MobileOnly from "@library/frontend/design-system/MobileOnly/MobileOnly";

import Navigation from "./components/Navigation";
import { Logo } from "./components/Logo";
import { Socials } from "./components/Socials";
import { ForInvestorsSmall } from "./components/mobile/ForInvestorsSmall";
import { Hamburger } from "./components/mobile/Hamburger";
import { MenuMobile } from "./components/mobile/MenuMobile";

import "./Navbar.scss";

export const Navbar = () => {
  return (
    <>
      <header className="navbar">
        <div className="spacing">
          <Group fullSize justifyContent="space-between" alignItems="center">
            <Logo />
            <DesktopOnly breakpoint={"md"}>
              <Navigation />
              <Socials />
            </DesktopOnly>
            <MobileOnly breakpoint="md">
              <Group colGap={16} fullHeight>
                <ForInvestorsSmall />
                <Hamburger />
              </Group>
            </MobileOnly>
          </Group>
        </div>
        <MobileOnly breakpoint={"md"}>
          <MenuMobile />
        </MobileOnly>
      </header>
    </>
  );
};
