import useInput from "@library/frontend/hooks/useInput";
import useValidateString from "@library/frontend/hooks/useValidateString";

export interface CompleteCodeReturn {
  changeCode: (newCode: string) => void;
  isCorrect: boolean;
  errors: string[];
  value: string;
  isEmpty: boolean;
}

const useCompleteCode = (): CompleteCodeReturn => {
  const code = useInput();

  const validator = useValidateString({ minLength: 6, maxLength: 8, stringName: "Code" });

  const changeCode = (newCode: string) => {
    code.update(newCode);
    validator.validate(newCode);
  };

  const isCorrect = validator.validationResult.isValid;
  const isEmpty = code.isEmpty;
  const errors = validator.validationResult.errors;
  const value = code.value;

  return { changeCode, isCorrect, errors, value, isEmpty };
};

export default useCompleteCode;
