import Stack from "@library/frontend/design-system/Stack/Stack";

import ProgressBar from "@library/frontend/design-system/ProgressBar/ProgressBar";
import { useStepper } from "@library/frontend/hooks/useStepper";

interface Props {
  title: string;
}

export const ModalContentVerifying = ({ title }: Props) => {
  const stepper = useStepper({ stepsQuantity: 100, countNextEveryMs: 25 });
  return (
    <Stack className="modal-content-verifying" rowGap={24} fullWidth alignItems="center">
      <h3 className="title">{title}</h3>
      <ProgressBar fillPercentage={`${stepper.currentStep}%`} />
      <p className="description">Processing your document.</p>
    </Stack>
  );
};
