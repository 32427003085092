import React from "react";

import { IChildren } from "@library/frontend/interfaces/IChildren";
import useBoolean from "@library/frontend/hooks/useBoolean";

interface IMobileMenuCtx {
  isOpen: boolean;
  open(): void;
  close(): void;
  toggleOpen(): void;
}

const MobileMenuCtx = React.createContext(null as any);

export const MobileMenuProvider = ({ children }: IChildren) => {
  const [isOpen, open, close, toggleOpen] = useBoolean(false);
  return <MobileMenuCtx.Provider value={{ isOpen, open, close, toggleOpen }}>{children}</MobileMenuCtx.Provider>;
};

export const useMobileMenuCtx = (): IMobileMenuCtx => React.useContext(MobileMenuCtx);
