import PaddingSectionTemplate from "@app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";

import Stack from "@library/frontend/design-system/Stack/Stack";
import Group from "@library/frontend/design-system/Group/Group";

import { TOKEN_TICKER_EMPTY } from "@integrations/qcv-token";
import { IChildren } from "@library/frontend/interfaces/IChildren";

import "./Roadmap.scss";

export const Roadmap = () => {
  return (
    <section className="roadmap">
      <PaddingSectionTemplate>
        <Stack fullWidth rowGap={24}>
          <Stack fullWidth>
            <p className="tagline">Future plans</p>
            <Group fullWidth colGap={24} alignItems="center">
              <h4 className="title">Roadmap 2025</h4>
              <Group fullWidth>
                <hr className="line" />
                <img src="/images/icons/arrow-right.svg" />
              </Group>
            </Group>
          </Stack>
          <Cards />
        </Stack>
      </PaddingSectionTemplate>
    </section>
  );
};

const q1 = [
  {
    isChecked: true,
    label: "AI Tool in ChatGPT"
  },
  {
    isChecked: true,
    label: "Blockchain Resume Verification"
  },
  {
    isChecked: false,
    label: `${TOKEN_TICKER_EMPTY}Token Launch`
  },
  {
    isChecked: false,
    label: "Blockchain Integration"
  },
  {
    isChecked: false,
    label: "Platform Launch"
  },
  {
    isChecked: false,
    label: "Talent Acquisition AI Tool"
  }
];
const q2 = [
  {
    isChecked: false,
    label: "Community Growth"
  },
  {
    isChecked: false,
    label: `Marketing Boost`
  },
  {
    isChecked: false,
    label: "Partnerships with HR Companies"
  },
  {
    isChecked: false,
    label: "Web3 Partnerships"
  },
  {
    isChecked: false,
    label: "Integration with Leading AI Engines"
  }
];
const q3 = [
  {
    isChecked: false,
    label: "Talent Scoring AI Tool"
  },
  {
    isChecked: false,
    label: `Premium Jobs Marketplace`
  },
  {
    isChecked: false,
    label: "B2B Adoption"
  },
  {
    isChecked: false,
    label: "Revenue Sharing"
  },
  {
    isChecked: false,
    label: "CEX Listing"
  }
];
const q4 = [
  {
    isChecked: false,
    label: "Predictive AI Analytics"
  },
  {
    isChecked: false,
    label: `Platform Integrations`
  },
  {
    isChecked: false,
    label: "100,000 Users Milestone"
  }
];

const data = [q1, q2, q3, q4];

const Cards = () => {
  return (
    <Group colGap={8} rowGap={8} alignItems="stretch" className="wrap" fullWidth>
      {data.map((q, id) => (
        <Card key={id}>
          <Group colGap={16} fullSize>
            <h6>{`Q${id + 1}`}</h6>
            <Stack rowGap={8} fullSize justifyContent="center">
              {q.map((it, id) => (
                <Group fullWidth key={id} colGap={8} style={{ maxWidth: "201.5px" }}>
                  <Checkbox isChecked={it.isChecked} />
                  <p>{it.label}</p>
                </Group>
              ))}
            </Stack>
          </Group>
        </Card>
      ))}
    </Group>
  );
};

const Card = ({ children }: IChildren) => {
  return <div className="card">{children}</div>;
};

const Checkbox = ({ isChecked }: { isChecked: boolean }) => {
  return <img src={`/images/icons/checkbox${isChecked ? "-checked" : ""}.svg`} />;
};
