import { QUBE_APP_URL } from "@integrations/external-links";
import Button from "@library/frontend/design-system/Button/Button";
import Group from "@library/frontend/design-system/Group/Group";

import "./LoginPlatform.scss";
import { BaseProps } from "@library/frontend/interfaces/BaseProps";

export const LoginPlatformBtn = (props: BaseProps) => {
  return (
    <Button as="a" href={QUBE_APP_URL} className={props.className} style={props.style}>
      <div className="login-platform">
        <Group fullSize colGap={8} justifyContent="center">
          <p className="label primary">Login to platform</p>
          <img src="/images/icons/door-out.svg" />
        </Group>
      </div>
    </Button>
  );
};
