import PaddingSectionTemplate from "src/app/frontend/view/templates/PaddingSectionTemplate/PaddingSectionTemplate";
import Stack from "@library/frontend/design-system/Stack/Stack";
import Group from "@library/frontend/design-system/Group/Group";

import Button from "@library/frontend/design-system/Button/Button";
import useIsMobile from "@library/frontend/hooks/useIsMobile";

import "./MediaAboutUs.scss";

const media = [
  {
    url: "",
    img: "/images/media/yahoo.png",
    cite: "lorem ipsum dolor sit amet. consectetur ajsgjajgsasokgaks"
  },
  {
    url: "",
    img: "/images/media/pr-inside.png",
    cite: "lorem ipsum dolor sit amet. consectetur ajsgjajgsasokgaks"
  },
  {
    url: "",
    img: "/images/media/markets-insider.png",
    cite: "lorem ipsum dolor sit amet. consectetur ajsgjajgsasokgaks"
  }
];

const MediaAboutUs = () => {
  const isMobile = useIsMobile("sm-l");
  return (
    <section className="media-about-us">
      <PaddingSectionTemplate>
        <Stack fullWidth rowGap={32}>
          <p className="title m-xs-a black">Media about us</p>
          <Group
            fullWidth
            rowGap={32}
            colGap={32}
            justifyContent={isMobile ? "center" : "space-between"}
            className="wrap"
          >
            {media.map((m, i) => (
              <Stack rowGap={16} alignItems="center" key={i} style={{ width: "100%", maxWidth: "240px" }}>
                <img src={m.img} />
                {/* <cite className="t-xs-c black">{`\"${m.cite}\"`}</cite>
                <Button as="a" href={m.url}>
                  <Group colGap={8}>
                    <p className="black" style={{ textDecoration: "underline", textUnderlineOffset: "4px" }}>
                      read article
                    </p>
                    <img src="/images/icons/book.svg" />
                  </Group>
                </Button> */}
              </Stack>
            ))}
          </Group>
        </Stack>
      </PaddingSectionTemplate>
    </section>
  );
};

export default MediaAboutUs;
