import { useState, useCallback } from "react";

interface UseValidateStringProps {
  stringName: string;
  minLength?: number;
  maxLength?: number;
  pattern?: RegExp;
  customValidator?: (value: string) => boolean;
}

interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

interface UseValidateStringResult {
  validate: (value: string) => ValidationResult;
  validationResult: ValidationResult;
}

const useValidateString = ({
  stringName,
  minLength,
  maxLength,
  pattern,
  customValidator
}: UseValidateStringProps): UseValidateStringResult => {
  const [validationResult, setValidationResult] = useState<ValidationResult>({
    isValid: false,
    errors: []
  });

  const validate = useCallback(
    (value: string): ValidationResult => {
      const errors: string[] = [];

      if (minLength !== undefined && value.length < minLength) {
        errors.push(`${stringName} must be at least ${minLength} characters long.`);
      }

      if (maxLength !== undefined && value.length > maxLength) {
        errors.push(`${stringName} must be no more than ${maxLength} characters long.`);
      }

      if (pattern && !pattern.test(value)) {
        errors.push(`${stringName} does not match the required pattern.`);
      }

      if (customValidator && !customValidator(value)) {
        errors.push(`${stringName} does not meet the custom validation criteria.`);
      }

      const isValid = errors.length === 0;

      const result = { isValid, errors };
      setValidationResult(result);
      return result;
    },
    [minLength, maxLength, pattern, customValidator]
  );

  return { validate, validationResult };
};

export default useValidateString;
